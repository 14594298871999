import React from "react"
import { graphql } from "gatsby"
import Layout from "@layouts/layout"
import RunningPlan from "@elements/RunningPlan"
import { HelmetDatoCms } from "gatsby-source-datocms"

const RunningPlanDetail = ({
  data: { runningPlan },
  pageContext: { parent }
}) => {
  return (
    <Layout parent={parent}>
      <HelmetDatoCms defer={false} seo={runningPlan.seoMetaTags} />
      <RunningPlan {...runningPlan} />
    </Layout>
  )
}

export const query = graphql`
  query runningPlanById($id: String) {
    runningPlan: datoCmsRunningPlan(id: { eq: $id }) {
      ...RunningPlan
    }
  }
`

export default RunningPlanDetail
