import React from "react"
import { Link } from "gatsby"

const RunningPlanCell = ({ rowId, links = [], text = "" }) => {
  return (
    <React.Fragment>
      {links.length > 0 &&
        links.map(({ id, title, path }, i) => (
          <Link key={rowId + id + i} to={path}>
            {title}
          </Link>
        ))}
      {text && text}
    </React.Fragment>
  )
}

const runningPlanBody = trainingPlan =>
  trainingPlan.map(row => {
    const config = runningPlanColumnsConfig(row)

    return config.map(config => <RunningPlanCell {...config} />)
  })

const runningPlanColumnsConfig = row => [
  {
    rowId: row.id,
    text: row.week
  },
  {
    rowId: row.id,
    text: row.monday
  },
  {
    rowId: row.id,
    text: row.tuesday
  },
  {
    rowId: row.id,
    text: row.wednesday
  },
  {
    rowId: row.id,
    text: row.thursday
  },
  {
    rowId: row.id,
    text: row.friday
  },
  {
    rowId: row.id,
    text: row.saturday
  },
  {
    rowId: row.id,
    text: row.sunday
  }
]

export { runningPlanColumnsConfig, runningPlanBody, RunningPlanCell }
