import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import style from "./runningPlan.mod.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import ModuleArea from "@helpers/moduleArea"
import Table from "@elements/Table"
import { dayColumns } from "@helpers/mealplanHelpers"
import { runningPlanBody } from "@helpers/runningPlanHelpers"
import PropTypes from "prop-types"
import Block from "@global/layout/Block"

const RunningPlan = ({
  title,
  banner,
  description,
  distance,
  planPdf,
  trainingPlan
}) => {
  const text = useStaticQuery(graphql`
    {
      content: contentYaml(language: { eq: "en-NZ" }) {
        ...MealPlanContent
      }
    }
  `)

  return (
    <div className={style.runningPlan}>
      <GatsbyImage
        className={style.runningPlan__image}
        image={banner.gatsbyImageData}
      />
      <Block padding="both" gutters={true} maxWidth="inner">
        <div className={style.runningPlan__content}>
          <p className={style.runningPlan__distance}>{distance} km</p>
          <h1 className={style.runningPlan__title}>{title}</h1>
          {description && (
            <p className={style.runningPlan__description}>{description}</p>
          )}
        </div>
      </Block>
      <Table
        head={["week", ...dayColumns(text.content.dayNames)]}
        body={runningPlanBody(trainingPlan)}
      />
      {planPdf && <ModuleArea modules={planPdf} />}
    </div>
  )
}

export default RunningPlan

RunningPlan.propTypes = {
  title: PropTypes.string.isRequired,
  banner: PropTypes.object.isRequired,
  distance: PropTypes.number.isRequired,
  description: PropTypes.string,
  planPdf: PropTypes.array,
  trainingPlan: PropTypes.array.isRequired
}

export const query = graphql`
  fragment RunningPlan on DatoCmsRunningPlan {
    id
    slug
    title
    distance
    description
    seoMetaTags {
      ...GatsbyDatoCmsSeoMetaTags
    }
    banner {
      gatsbyImageData(imgixParams: { fit: "crop", w: "2400", h: "1200" })
    }

    ...PdfModuleArea

    trainingPlan {
      ... on DatoCmsTrainingPlanRow {
        id
        week
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        model {
          apiKey
        }
      }
    }
  }

  fragment MealPlanContent on ContentYaml {
    mealPlanPdfs {
      grocery
      mealPlan
    }
    dayNames {
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
    }
  }
`
